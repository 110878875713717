<template>
	<div class="a-info-section">
		<img src="./icon/warning.png" class="info-icon"/>
		<div class="info-text">
			<h4>Tento kurz si dostal/a ako úlohu.<br>Pre jej splnenie vyplň kvíz.<br>Boduje sa iba prvé vyplnenie kvízu v rámci tejto úlohy.</h4>
			<div class="task-info">
				<div>
					<p>Trieda:</p>
					<p>Učiteľ:</p>
					<p>Termín splnenia:</p>
				</div>
				<div class="ml-2 ml-sm-5">
					<p class="fw-bold">{{`${taskNote.group.name} ${taskNote.group.second_name}`}}</p>
					<p class="fw-bold">{{groupTeacher.name}} {{groupTeacher.surname}}</p>
					<p class="fw-bold">{{taskDeadline}}</p>
				</div>
			</div>
		</div>
		<b-button variant="outline-white" class="w-color-white mr-3 note-button" @click="$refs['note-modal'].showModal()">
			Ukázať poznámku od učiteľa
		</b-button>
		<a-lesson-tabular-note-modal ref="note-modal" :note="taskNote.note"/>
	</div>
</template>

<script>
import moment from 'moment-timezone'

export default {
	components: {
		'a-lesson-tabular-note-modal': () => import('./a-lesson-tabular-note-modal.vue'),
	},

	props: {
		taskNote: {
			type: Object,
			required: true
		}
	},

	computed: {
		groupTeacher() {
			return this.taskNote.group.members.filter(member => member.role == 'admin')[0].user
		},
		taskDeadline() {
			return moment.utc(this.taskNote.deadline).tz('Europe/Bratislava').format('DD.MM.YYYY HH:mm')
		}
	}
}
</script>

<style lang="scss" scoped>

.a-info-section {

		.info-icon {
			width: 70px;
			margin: 0 40px;
		}
		.note-button {
			height: 20px;
			width: 50%;
			font-size: 14px;
			padding: 5px 10px 25px 5px;

			&:focus {
				background-color: transparent;
			}
			&:hover {
				background-color: white;
			}
		}

		@media only screen and (max-width: 991px) {
			.note-button {
				font-size: 10px;
			}
			h4 {
				font-size: 10px;
			}
			.info-icon {
				width: 35px;
				margin: 0 10px;
			}
		}
		@media only screen and (max-width: 555px) {
			.note-button {
				font-size: 9px;
				line-height: 10px;
			}
		}

		.task-info {
			display: flex;
		}
		.info-text {
			height: 100%;
			width: 100%;
			display: flex;
			flex-direction: column;
			justify-content: center;

			* {
				color: white;
			}
			
			p {
				font-size: 14px;
				margin-top: 12px;
				margin-bottom: 0px;

				@media only screen and (max-width: 1400px) {
					margin-top: 0px;
					font-size: 10px;
				}
			}
		}
	}
</style>